import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  MailOutline as MailOutlineIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { fetchAllMessages } from 'store/actions/messages';
import { messageSelectors } from 'store/selectors/messages';

import {
  ActionCenterSelectDialog,
  AddCampaignDialog,
  Container,
  CreateMessageButton,
  DescriptionBar,
} from 'common/components';
import { CircularProgressWrapper } from 'globalStyles';
import {
  actionTypes,
  statuses,
} from 'utils/constants';
import AlertListItem from '../components/AlertListItem';
import BroadcastListItem from '../components/BroadcastListItem';

const BroadcastListWrapper = styled(Grid)`
  padding-top: 30px;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

const StyledSelect = styled(Select)`
  margin-top: 16px;
  margin-right: 10px;
  padding-right: 10px;
`;

const StyledStartDatePicker = styled(DatePicker)`
  width: 150px;
`;

const StyledEndDatePicker = styled(DatePicker)`
  width: 150px;
  margin-left: 20px;
`;

const nilUuid = '00000000-0000-0000-0000-000000000000';

const Messages = () => {
  const dispatch = useDispatch();

  const { organizationUuid } = useParams();
  const {
    draft,
    published,
  } = statuses;
  const {
    email,
    text,
  } = actionTypes;

  const {
    allBroadcastsList,
    isBroadcastsFetching,
  } = useSelector(state => ({
    allBroadcastsList: messageSelectors.getAllMessagesListData(state),
    isBroadcastsFetching: messageSelectors.isMessagesFetching(state),
  }));

  const [
    isCampaignDialogOpen,
    setIsCampaignDialogOpen,
  ] = useState(false);
  const [
    isAlertDialogOpen,
    setIsAlertDialogOpen,
  ] = useState(false);
  const [
    newAlertType,
    setNewAlertType,
  ] = useState('email');
  const [
    status,
    setStatus,
  ] = useState(0);
  const [
    purpose,
    setPurpose,
  ] = useState(0);
  const [
    type,
    setType,
  ] = useState(0);
  const [
    messages,
    setMessages,
  ] = useState(null);
  const [
    startDate,
    setStartDate,
  ] = useState(null);
  const [
    endDate,
    setEndDate,
  ] = useState(null);
  const [
    pagination,
    setPagination,
  ] = useState(10);

  useEffect(() => {
    dispatch(fetchAllMessages({ organization: organizationUuid }));
  }, [
    organizationUuid,
    dispatch,
  ]);

  useEffect(() => {
    let filteredBroadcasts = allBroadcastsList || [];

    if (status) {
      filteredBroadcasts = filteredBroadcasts.filter(broadcast => (
        status === 1 ? broadcast.status === published : broadcast.status === draft));
    }

    if (purpose) {
      filteredBroadcasts = filteredBroadcasts.filter(broadcast => (
          purpose === 1 ? broadcast.campaign === nilUuid : broadcast.campaign !== nilUuid));
    }

    if (startDate) {
      filteredBroadcasts = filteredBroadcasts.filter(broadcast => (
        new Date(broadcast.createdAt) >= startDate));
    }

    if (endDate) {
      filteredBroadcasts = filteredBroadcasts.filter(broadcast => (
        new Date(broadcast.createdAt) <= endDate));
    }

    if (type) {
      filteredBroadcasts = filteredBroadcasts.filter(broadcast => (
        type === email ? broadcast.type === email : broadcast.type === text));
    }

    filteredBroadcasts = filteredBroadcasts.slice(0, pagination);
    setMessages(filteredBroadcasts);
  }, [
    allBroadcastsList,
    endDate,
    pagination,
    purpose,
    status,
    startDate,
    type,
  ]);

  const handleCloseModal = () => {
    setIsCampaignDialogOpen(false);
  };

  const handleOpenAlerts = (alertType = 'email') => {
    setNewAlertType(alertType);
    setIsAlertDialogOpen(true);
  };

  if (isBroadcastsFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          icon={MailOutlineIcon}
          name="All Messages"
        >
          <CreateMessageButton
            alertDialogHandler={handleOpenAlerts}
          />
        </DescriptionBar>
      </Grid>
      <AddCampaignDialog
        handleDialogClose={handleCloseModal}
        isDialogOpen={isCampaignDialogOpen}
        subtitle="Broadcasts Name"
        title="Create a Broadcasts"
      />
      <Grid
        container
        justifyContent="flex-end"
      >
        <Grid>
          <StyledSelect
            onChange={event => setPurpose(event.target.value)}
            value={purpose}
            label="Type"
            variant="standard"
          >
            <MenuItem value={0}>All messages</MenuItem>
            <MenuItem value={1}>Broadcasts</MenuItem>
            <MenuItem value={2}>Action alerts</MenuItem>
          </StyledSelect>
          <FormHelperText>Type</FormHelperText>
        </Grid>
        <Grid>
          <StyledSelect
            onChange={event => setStatus(event.target.value)}
            value={status}
            label="Status"
            variant="standard"
          >
            <MenuItem value={0}>Any status</MenuItem>
            <MenuItem value={1}>Sent</MenuItem>
            <MenuItem value={2}>Draft</MenuItem>
          </StyledSelect>
          <FormHelperText>Status</FormHelperText>
        </Grid>
        <Grid>
          <StyledSelect
            onChange={event => setType(event.target.value)}
            value={type}
            variant="standard"
          >
            <MenuItem value={0}>Email & text</MenuItem>
            <MenuItem value={email}>Email</MenuItem>
            <MenuItem value={text}>Text</MenuItem>
          </StyledSelect>
          <FormHelperText>Channel</FormHelperText>
        </Grid>
        <Grid>
          <Grid>
            <StyledStartDatePicker
              clearable
              onChange={date => setStartDate(date ? dayjs(date).startOf('day')
                .toDate() : null)
              }
              label="Start Date"
              value={startDate ? dayjs(startDate) : null}
              slotProps={{ textField: { variant: 'standard' } }}
            />
            <StyledEndDatePicker
              clearable
              label="End Date"
              onChange={date => setEndDate(date ? dayjs(date).endOf('day')
                .toDate() : null)
              }
              value={endDate ? dayjs(endDate) : null}
              slotProps={{ textField: { variant: 'standard' } }}
            />
            <FormHelperText>Date range</FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <BroadcastListWrapper container>
        {!messages?.length ? (
          <Typography>
            {allBroadcastsList?.length ?
              'No results for these criteria' :
              'There are no broadcasts yet'
            }
          </Typography>
        ) : (
          messages.map(({
            bounceRate,
            createdBy,
            name,
            openRate,
            sendAt,
            sendAtUtc,
            status: broadcastStatus,
            spamRate,
            totalRecipientsCount,
            totalOpenedCount,
            totalClicksCount,
            totalBouncedCount,
            totalUnsubscribedCount,
            type: broadcastType,
            subject,
            uuid,
            campaign,
            actionCenter,
          }) => (
            campaign !== nilUuid ? (
              <AlertListItem
                bounceRate={bounceRate}
                createdBy={createdBy}
                key={uuid}
                name={name}
                openRate={openRate}
                organizationUuid={organizationUuid}
                sendAt={sendAt}
                sendAtUtc={sendAtUtc}
                status={broadcastStatus}
                spamRate={spamRate}
                totalRecipientsCount={totalRecipientsCount}
                totalOpenedCount={totalOpenedCount}
                totalClickedCount={totalClicksCount}
                totalBouncedCount={totalBouncedCount}
                totalUnsubscribedCount={totalUnsubscribedCount}
                type={broadcastType}
                subject={subject}
                uuid={uuid}
                campaign={campaign}
                actionCenter={actionCenter}
              />
            ) : (
              <BroadcastListItem
                bounceRate={bounceRate}
                createdBy={createdBy}
                key={uuid}
                name={name}
                openRate={openRate}
                organizationUuid={organizationUuid}
                sendAt={sendAt}
                sendAtUtc={sendAtUtc}
                status={broadcastStatus}
                spamRate={spamRate}
                totalRecipientsCount={totalRecipientsCount}
                totalOpenedCount={totalOpenedCount}
                totalClickedCount={totalClicksCount}
                totalBouncedCount={totalBouncedCount}
                totalUnsubscribedCount={totalUnsubscribedCount}
                type={broadcastType}
                subject={subject}
                uuid={uuid}
              />
            )
          )))}
      </BroadcastListWrapper>
      <ActionCenterSelectDialog
        organizationUuid={organizationUuid}
        newAlertType={newAlertType}
        isAlertDialogOpen={isAlertDialogOpen}
        setIsAlertDialogOpen={setIsAlertDialogOpen}
      />
      <LoadMoreWrapper>
        {
          !!allBroadcastsList?.length && (allBroadcastsList.length > pagination) && (
            <Button
              onClick={() => setPagination(prevValue => prevValue + 10)}
              variant="outlined"
            >
              Load More
            </Button>
          )
        }
      </LoadMoreWrapper>
    </Container>
  );
};

export default Messages;

