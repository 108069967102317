import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import {
  MailOutline as MailOutlineIcon,
  Sms as SmsIcon,
} from '@mui/icons-material';

import {
  DescriptionBarInfo,
  StatisticListCard,
} from 'common/components';
import { renderChip } from 'utils/helpers';
import { actionTypes } from 'utils/constants';

const StyledCard = styled(Card)`
  overflow-x: auto;
  display: flex;
  width: 100%;
  padding: 26px 0 36px;
`;

const StyledGrid = styled(Grid)`
  padding-left: 50px;
`;

const StyledTypography = styled(Typography)`
  padding-right: 10px;
`;

const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;

const broadcastUrl = (type, organizationUuid, uuid) => {
  const {
    email,
  } = actionTypes;

  return type === email ? `/${organizationUuid}/broadcasts/${uuid}` : `/${organizationUuid}/text-broadcasts/${uuid}`;
};

const BroadcastListItem = ({
  organizationUuid,
  createdBy,
  name,
  sendAt,
  sendAtUtc,
  subject,
  totalBouncedCount,
  totalClickedCount,
  totalRecipientsCount,
  totalOpenedCount,
  totalUnsubscribedCount,
  type,
  status,
  uuid,
}) => {
  const {
    text,
  } = actionTypes;

  return (
    <StyledCard variant="outlined">
      <Grid
        container
        item
      >
        <StyledGrid
          container
          item
        >
          <Link
            component={RouterLink}
            to={broadcastUrl(type, organizationUuid, uuid)}
          >
            <StyledTypography variant="h5">
              {name}
            </StyledTypography>
            {renderChip(status, sendAtUtc, type === text ? <SmsIcon /> : <MailOutlineIcon />)}
          </Link>
        </StyledGrid>
        <DescriptionBarInfo
          createdBy={createdBy}
          detailsText="Broadcast details"
          href={broadcastUrl(type, organizationUuid, uuid)}
          isButtonVisible
          subject={subject}
          sendAt={sendAt}
          type={type}
        />
      </Grid>
      <Grid
        container
        item
      >
        <StatisticsWrapper>
          <StatisticListCard
            count={totalRecipientsCount}
            hasPrimaryColor
            text="Recipients"
          />
          {type !== text && (
          <>
            <StatisticListCard
              count={totalOpenedCount}
              text="Opened"
            />
            <StatisticListCard
              count={totalClickedCount}
              negative
              text="Clicked"
            />
            <StatisticListCard
              count={totalBouncedCount}
              negative
              text="Bounced"
            />
            <StatisticListCard
              count={totalUnsubscribedCount}
              negative
              text="Unsubscribed"
            />
          </>
          )}
        </StatisticsWrapper>
      </Grid>
    </StyledCard>
  );
};

BroadcastListItem.defaultProps = {
  createdBy: null,
  name: '',
  sendAt: '',
  sendAtUtc: '',
  subject: '',
};

BroadcastListItem.propTypes = {
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  name: PropTypes.string,
  organizationUuid: PropTypes.string.isRequired,
  sendAt: PropTypes.string,
  sendAtUtc: PropTypes.string,
  status: PropTypes.string.isRequired,
  subject: PropTypes.string,
  totalBouncedCount: PropTypes.number.isRequired,
  totalClickedCount: PropTypes.number.isRequired,
  totalOpenedCount: PropTypes.number.isRequired,
  totalRecipientsCount: PropTypes.number.isRequired,
  totalUnsubscribedCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default BroadcastListItem;
