import request from '../../utils/request';

const PREFIX = '[MESSAGES]';

export const FETCH_ALL_MESSAGES_REQUEST = `${PREFIX} FETCH_ALL_MESSAGES_REQUEST`;
export const FETCH_ALL_MESSAGES_SUCCESS = `${PREFIX} FETCH_ALL_MESSAGES_SUCCESS`;
export const FETCH_ALL_MESSAGES_FAILURE = `${PREFIX} FETCH_ALL_MESSAGES_FAILURE`;

const fetchAllMessagesRequest = () => ({
  type: FETCH_ALL_MESSAGES_REQUEST,
});

const fetchAllMessagesSuccess = payload => ({
  payload,
  type: FETCH_ALL_MESSAGES_SUCCESS,
});

const fetchAllMessagesFailure = payload => ({
  payload,
  type: FETCH_ALL_MESSAGES_FAILURE,
});

export const fetchAllMessages = params => dispatch => {
  dispatch(fetchAllMessagesRequest());

  return request
    .get('/v1/broadcasts/', { params })
    .then(({ data }) => {
      dispatch(fetchAllMessagesSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllMessagesFailure(data));

      throw data;
    });
};
