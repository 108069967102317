import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import {
  DetailsButton,
  StatisticListCard,
} from 'common/components';
import { colors } from 'utils/constants';

const DescriptionWrapper = styled(Grid)`
  padding-bottom: 20px;
`;

const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(DetailsButton)`
  width: 132px;
`;

const StyledCard = styled(Card)`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;

  &:not(:last-of-type) {
    border-bottom: 0;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${colors.osloGray};
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;

  &:first-of-type {
    padding-right: 20px;
  }
`;

const CampaignItem = ({
  createdAt,
  createdBy,
  name,
  showPetitions,
  statistics,
  uuid,
}) => {
  const { organizationUuid } = useParams();

  return (
    <StyledCard
      square
      variant="outlined"
    >
      <Grid
        container
        item
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Typography variant="h6">
          <Link
            component={RouterLink}
            to={`/${organizationUuid}/campaigns/${uuid}`}
          >
            {name}
          </Link>
        </Typography>
        <div>
          <StyledButton to={`/${organizationUuid}/campaigns/${uuid}`} />
        </div>
      </Grid>
      <DescriptionWrapper
        container
        item
        justifyContent="flex-start"
      >
        <StyledTypography variant="subtitle2">
          Active since
          &nbsp;
          {new Date(createdAt).toLocaleDateString('us-US')}
        </StyledTypography>
        <StyledTypography variant="subtitle2">
          Created by
          &nbsp;
          {createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : 'N/A'}
        </StyledTypography>
      </DescriptionWrapper>
      <Grid
        container
        item
        justifyContent="space-between"
      >
        <StatisticListCard
          count={statistics?.actionCenters?.totalCount}
          hasDaysStatistic
          hasDisabledElevation
          hasPrimaryColor
          lastMonthValue={statistics?.actionCenters?.last30DaysCount}
          lastWeekValue={statistics?.actionCenters?.last7DaysCount}
          text="Action centers"
        />
        <StatisticListCard
          count={statistics?.advocates?.totalCount}
          hasDaysStatistic
          hasDisabledElevation
          hasPrimaryColor
          lastMonthValue={statistics?.advocates?.last30DaysCount}
          lastWeekValue={statistics?.advocates?.last7DaysCount}
          text="Advocates"
        />
        <StatisticsWrapper>
          <StatisticListCard
            count={statistics?.actions?.totalCount}
            hasDaysStatistic
            hasDisabledElevation
            hasPrimaryColor
            lastMonthValue={statistics?.actions?.last30DaysCount}
            lastWeekValue={statistics?.actions?.last7DaysCount}
            text="Total actions"
          />
          <StatisticListCard
            count={statistics?.phoneCalls?.totalCount}
            hasDaysStatistic
            hasDisabledElevation
            lastMonthValue={statistics?.phoneCalls?.last30DaysCount}
            lastWeekValue={statistics?.phoneCalls?.last7DaysCount}
            text="Phone calls"
          />
          <StatisticListCard
            count={statistics?.emails?.totalCount}
            hasDaysStatistic
            hasDisabledElevation
            lastMonthValue={statistics?.emails?.last30DaysCount}
            lastWeekValue={statistics?.emails?.last7DaysCount}
            text="Emails"
          />
          {showPetitions && (
          <StatisticListCard
            count={statistics?.signatures?.totalCount}
            hasDaysStatistic
            hasDisabledElevation
            lastMonthValue={statistics?.signatures?.last30DaysCount}
            lastWeekValue={statistics?.signatures?.last7DaysCount}
            text="Signatures"
          />
          )}
        </StatisticsWrapper>
      </Grid>
    </StyledCard>
  );
};

CampaignItem.defaultProps = {
  createdAt: '',
  createdBy: null,
  name: '',
  showPetitions: false,
};

CampaignItem.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  name: PropTypes.string,
  showPetitions: PropTypes.bool,
  statistics: PropTypes.shape({
    actionCenters: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
    actions: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
    advocates: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
    emails: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
    phoneCalls: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
    signatures: PropTypes.shape({
      last7DaysCount: PropTypes.number,
      last30DaysCount: PropTypes.number,
      totalCount: PropTypes.number,
    }),
  }).isRequired,
  uuid: PropTypes.string.isRequired,
};

export default CampaignItem;
