import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  Form,
} from 'react-final-form';

import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import {
  fetchApiKeys,
  postApiKey,
} from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';

import { useQuery } from 'utils/helpers';
import { useCopyToClipboard } from 'utils/hooks';
import {
  TabPanel,
  Tabs,
  ZapierComponent,
} from 'common/components';
import {
  SettingsWrapper,
} from 'globalStyles';
import ApiKeysTable from '../ApiKeysTable';

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const StyledDivider = styled(Divider)`
  display: block;
  margin-bottom: 20px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 40px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const ZapierKeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  border: 2px dotted black;
  padding: 12px;
`;

const ZapierComponentWrapper = styled.div`
  margin-top: 20px;
`;

const ZapierKeyValue = styled(Typography)`
  display: block;
  padding: 10px;
  text-align: center;
`;

const ZapierButton = styled(Button)`
  margin: 0 auto;
`;

const IntegrationsTab = ({ flags }) => {
  const { organizationUuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const tabIndex = query.get('integration');
  const [copy] = useCopyToClipboard();
  const [
    oneTimeVisibleKey,
    setOneTimeVisibleKey,
  ] = useState('');

  const {
    apiKeysData,
  } = useSelector(state => ({
    apiKeysData: organizationSelectors.getApiKeysDetails(state),
  }));

  useEffect(() => {
    dispatch(fetchApiKeys(organizationUuid));
  }, []);

  const handleCreateNewApiKey = async values => {
    try {
      const apiKeyData = await dispatch(postApiKey(organizationUuid, values));

      setOneTimeVisibleKey(apiKeyData.key);
      await dispatch(fetchApiKeys(organizationUuid));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const handleCopyToClipboard = () => {
    copy(oneTimeVisibleKey);
  };

  const handleSetTab = newValue => {
    history.push({
      search: `?tab=integrations&integration=${newValue}`,
    });
  };

  const integrationsTabs = [
    {
      disabled: flags ?
        !flags.showSalesforce :
        true,
      label: 'Salesforce',
      value: 'salesforce',
    },
    {
      disabled: flags ?
        !flags.showApi :
        true,
      label: 'Zapier',
      value: 'zapier',
    },
  ];

  return (
    <SettingsWrapper>
      <StyledGrid>
        <StyledPaper
          square
          variant="outlined"
        >
          <Tabs
            handleSetTab={handleSetTab}
            tabs={integrationsTabs}
            tabIndex={tabIndex || false}
          />
        </StyledPaper>
        <TabPanel
          index={integrationsTabs[0].value}
          value={tabIndex || false}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Salesforce Integration
          </Typography>
          <StyledDivider />
          <Typography
            variant="h6"
            gutterBottom
          >
            Create a new Key
          </Typography>
          <Form
            onSubmit={handleCreateNewApiKey}
            initialValues={{ scope: 'salesforce' }}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <StyledField name="name">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="name"
                          type="text"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <StyledGrid
                    item
                    xs={3}
                  >
                    <StyledButton
                      color="secondary"
                      disabled={submitting || pristine}
                      disableElevation
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </StyledButton>
                  </StyledGrid>
                </Grid>
              </form>
            )}
          />
          {oneTimeVisibleKey && (
          <ZapierKeyWrapper>
            <Typography variant="h6">
              This key is shown only once! Please copy it for yourself now,
              you won&apos;t be able to do it after you go to the next page:
            </Typography>
            <ZapierKeyValue>{oneTimeVisibleKey}</ZapierKeyValue>
            <ZapierButton
              variant="contained"
              onClick={handleCopyToClipboard}
            >
              Copy to Clipboard
            </ZapierButton>
          </ZapierKeyWrapper>
          )}
          <Typography
            variant="h6"
            gutterBottom
          >
            API Keys
          </Typography>
          <ApiKeysTable
            apiKeysData={apiKeysData}
            organizationUuid={organizationUuid}
          />
        </TabPanel>
        <TabPanel
          index={integrationsTabs[1].value}
          value={tabIndex || false}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Zapier Integration
          </Typography>
          <StyledDivider />
          <Typography
            variant="h6"
            gutterBottom
          >
            Create a new Key
          </Typography>
          <Form
            onSubmit={handleCreateNewApiKey}
            initialValues={{ scope: 'zapier' }}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <StyledField name="name">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="name"
                          type="text"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <StyledGrid
                    item
                    xs={3}
                  >
                    <StyledButton
                      color="secondary"
                      disabled={submitting || pristine}
                      disableElevation
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </StyledButton>
                  </StyledGrid>
                </Grid>
              </form>
            )}
          />
          {oneTimeVisibleKey && (
            <ZapierKeyWrapper>
              <Typography variant="h6">
                This key is shown only once! Please copy it for yourself now,
                you won&apos;t be able to do it after you go to the next page:
              </Typography>
              <ZapierKeyValue>{oneTimeVisibleKey}</ZapierKeyValue>
              <ZapierButton
                variant="contained"
                onClick={handleCopyToClipboard}
              >
                Copy to Clipboard
              </ZapierButton>
            </ZapierKeyWrapper>
          )}
          <Typography
            variant="h6"
            gutterBottom
          >
            Zapier Keys
          </Typography>
          <ApiKeysTable
            apiKeysData={apiKeysData}
            organizationUuid={organizationUuid}
          />
          <ZapierComponentWrapper>
            <Typography
              variant="h6"
              gutterBottom
            >
              Edit and Add Zaps
            </Typography>
            <StyledDivider />
            <ZapierComponent />
          </ZapierComponentWrapper>
        </TabPanel>
      </StyledGrid>
    </SettingsWrapper>
  );
};

IntegrationsTab.defaultProps = {
  flags: null,
};

IntegrationsTab.propTypes = {
  flags: PropTypes.shape({
    showApi: PropTypes.bool,
    showSalesforce: PropTypes.bool,
  }),
};

export default IntegrationsTab;
