import request from '../../utils/request';

const PREFIX = '[ORGANIZATIONS]';

export const FETCH_ORGANIZATION_REQUEST = `${PREFIX} FETCH_ORGANIZATION_REQUEST`;
export const FETCH_ORGANIZATION_SUCCESS = `${PREFIX} FETCH_ORGANIZATION_SUCCESS`;
export const FETCH_ORGANIZATION_FAILURE = `${PREFIX} FETCH_ORGANIZATION_FAILURE`;

const fetchOrganizationRequest = () => ({
  type: FETCH_ORGANIZATION_REQUEST,
});

const fetchOrganizationSuccess = payload => ({
  payload,
  type: FETCH_ORGANIZATION_SUCCESS,
});

const fetchOrganizationFailure = payload => ({
  payload,
  type: FETCH_ORGANIZATION_FAILURE,
});

export const fetchOrganization = uuid => dispatch => {
  dispatch(fetchOrganizationRequest());

  return request
    .get(`/v1/accounts/organizations/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchOrganizationSuccess(data));

      return data;
    })
    .catch(({
      response: {
        status,
        data,
      } = {},
    }) => {
      dispatch(fetchOrganizationFailure(data));

      throw status;
    });
};

export const FETCH_ORGANIZATIONS_REQUEST = `${PREFIX} FETCH_ORGANIZATIONS_REQUEST`;
export const FETCH_ORGANIZATIONS_SUCCESS = `${PREFIX} FETCH_ORGANIZATIONS_SUCCESS`;
export const FETCH_ORGANIZATIONS_FAILURE = `${PREFIX} FETCH_ORGANIZATIONS_FAILURE`;

const fetchOrganizationsRequest = () => ({
  type: FETCH_ORGANIZATIONS_REQUEST,
});

const fetchOrganizationsSuccess = payload => ({
  payload,
  type: FETCH_ORGANIZATIONS_SUCCESS,
});

const fetchOrganizationsFailure = payload => ({
  payload,
  type: FETCH_ORGANIZATIONS_FAILURE,
});

export const fetchOrganizations = () => dispatch => {
  dispatch(fetchOrganizationsRequest());

  return request
    .get('/v1/accounts/organizations/')
    .then(({ data }) => {
      dispatch(fetchOrganizationsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchOrganizationsFailure(data));

      throw data;
    });
};

export const POST_ORGANIZATION_REQUEST = `${PREFIX} POST_ORGANIZATION_REQUEST`;
export const POST_ORGANIZATION_SUCCESS = `${PREFIX} POST_ORGANIZATION_SUCCESS`;
export const POST_ORGANIZATION_FAILURE = `${PREFIX} POST_ORGANIZATION_FAILURE`;

const postOrganizationRequest = () => ({
  type: POST_ORGANIZATION_REQUEST,
});

const postOrganizationSuccess = payload => ({
  payload,
  type: POST_ORGANIZATION_SUCCESS,
});

const postOrganizationFailure = payload => ({
  payload,
  type: POST_ORGANIZATION_FAILURE,
});

export const postOrganization = params => dispatch => {
  dispatch(postOrganizationRequest());

  return request
    .post('/v1/accounts/organizations/', params)
    .then(({ data }) => {
      dispatch(postOrganizationSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postOrganizationFailure(data));

      throw data;
    });
};

export const PUT_ORGANIZATION_REQUEST = `${PREFIX} PUT_ORGANIZATION_REQUEST`;
export const PUT_ORGANIZATION_SUCCESS = `${PREFIX} PUT_ORGANIZATION_SUCCESS`;
export const PUT_ORGANIZATION_FAILURE = `${PREFIX} PUT_ORGANIZATION_FAILURE`;

const putOrganizationRequest = () => ({
  type: PUT_ORGANIZATION_REQUEST,
});

const putOrganizationSuccess = (payload, uuid) => ({
  payload,
  type: PUT_ORGANIZATION_SUCCESS,
  uuid,
});

const putOrganizationFailure = payload => ({
  payload,
  type: PUT_ORGANIZATION_FAILURE,
});

export const putOrganization = (uuid, params) => dispatch => {
  dispatch(putOrganizationRequest());

  return request
    .put(`/v1/accounts/organizations/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(putOrganizationSuccess(data, uuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(putOrganizationFailure(data));

      throw data;
    });
};

export const PATCH_ORGANIZATION_REQUEST = `${PREFIX} PATCH_ORGANIZATION_REQUEST`;
export const PATCH_ORGANIZATION_SUCCESS = `${PREFIX} PATCH_ORGANIZATION_SUCCESS`;
export const PATCH_ORGANIZATION_FAILURE = `${PREFIX} PATCH_ORGANIZATION_FAILURE`;

const patchOrganizationRequest = () => ({
  type: PATCH_ORGANIZATION_REQUEST,
});

const patchOrganizationSuccess = (payload, uuid) => ({
  payload,
  type: PATCH_ORGANIZATION_SUCCESS,
  uuid,
});

const patchOrganizationFailure = payload => ({
  payload,
  type: PATCH_ORGANIZATION_FAILURE,
});

export const patchOrganization = (uuid, params) => dispatch => {
  dispatch(patchOrganizationRequest());

  return request
    .patch(`/v1/accounts/organizations/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchOrganizationSuccess(data, uuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchOrganizationFailure(data));

      throw data;
    });
};

export const POST_INVITE_ADMIN_REQUEST = `${PREFIX} POST_INVITE_ADMIN_REQUEST`;
export const POST_INVITE_ADMIN_SUCCESS = `${PREFIX} POST_INVITE_ADMIN_SUCCESS`;
export const POST_INVITE_ADMIN_FAILURE = `${PREFIX} POST_INVITE_ADMIN_FAILURE`;

const postInviteAdminRequest = () => ({
  type: POST_INVITE_ADMIN_REQUEST,
});

const postInviteAdminSuccess = payload => ({
  payload,
  type: POST_INVITE_ADMIN_SUCCESS,
});

const postInviteAdminFailure = payload => ({
  payload,
  type: POST_INVITE_ADMIN_FAILURE,
});

export const postInviteAdmin = (organizationUuid, params) => dispatch => {
  dispatch(postInviteAdminRequest());

  return request
    .post(`/v1/accounts/organizations/${organizationUuid}/invitations/`, params)
    .then(({ data }) => {
      dispatch(postInviteAdminSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postInviteAdminFailure(data));

      throw data;
    });
};

export const POST_JOIN_ADMIN_REQUEST = `${PREFIX} POST_JOIN_ADMIN_REQUEST`;
export const POST_JOIN_ADMIN_SUCCESS = `${PREFIX} POST_JOIN_ADMIN_SUCCESS`;
export const POST_JOIN_ADMIN_FAILURE = `${PREFIX} POST_JOIN_ADMIN_FAILURE`;

const postJoinAdminRequest = () => ({
  type: POST_JOIN_ADMIN_REQUEST,
});

const postJoinAdminSuccess = payload => ({
  payload,
  type: POST_JOIN_ADMIN_SUCCESS,
});

const postJoinAdminFailure = payload => ({
  payload,
  type: POST_JOIN_ADMIN_FAILURE,
});

export const postJoinAdmin = (organizationUuid, invitationUuid, params) => dispatch => {
  dispatch(postJoinAdminRequest());

  return request
    .post(`/v1/accounts/organizations/${organizationUuid}/join/${invitationUuid}/`, params)
    .then(({ data }) => {
      dispatch(postJoinAdminSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postJoinAdminFailure(data));

      throw data;
    });
};

export const FETCH_INVITATION_DETAILS_REQUEST = `${PREFIX} FETCH_INVITATION_DETAILS_REQUEST`;
export const FETCH_INVITATION_DETAILS_SUCCESS = `${PREFIX} FETCH_INVITATION_DETAILS_SUCCESS`;
export const FETCH_INVITATION_DETAILS_FAILURE = `${PREFIX} FETCH_INVITATION_DETAILS_FAILURE`;

const fetchInvitationDetailsRequest = () => ({
  type: FETCH_INVITATION_DETAILS_REQUEST,
});

const fetchInvitationDetailsSuccess = payload => ({
  payload,
  type: FETCH_INVITATION_DETAILS_SUCCESS,
});

const fetchInvitationDetailsFailure = payload => ({
  payload,
  type: FETCH_INVITATION_DETAILS_FAILURE,
});

export const fetchInvitationDetails = (organizationUuid, invitationUuid) => dispatch => {
  dispatch(fetchInvitationDetailsRequest());

  return request
    .get(`/v1/accounts/organizations/${organizationUuid}/join/${invitationUuid}/`)
    .then(({ data }) => {
      dispatch(fetchInvitationDetailsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchInvitationDetailsFailure(data));

      throw data;
    });
};

export const FETCH_INVITATIONS_REQUEST = `${PREFIX} FETCH_INVITATIONS_REQUEST`;
export const FETCH_INVITATIONS_SUCCESS = `${PREFIX} FETCH_INVITATIONS_SUCCESS`;
export const FETCH_INVITATIONS_FAILURE = `${PREFIX} FETCH_INVITATIONS_FAILURE`;

const fetchInvitationsRequest = () => ({
  type: FETCH_INVITATIONS_REQUEST,
});

const fetchInvitationsSuccess = payload => ({
  payload,
  type: FETCH_INVITATIONS_SUCCESS,
});

const fetchInvitationsFailure = payload => ({
  payload,
  type: FETCH_INVITATIONS_FAILURE,
});

export const fetchInvitations = organizationUuid => dispatch => {
  dispatch(fetchInvitationsRequest());

  return request
    .get(`/v1/accounts/organizations/${organizationUuid}/invitations/?status=pending`)
    .then(({ data }) => {
      dispatch(fetchInvitationsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchInvitationsFailure(data));

      throw data;
    });
};

export const FETCH_TEAM_MEMBERS_REQUEST = `${PREFIX} FETCH_TEAM_MEMBERS_REQUEST`;
export const FETCH_TEAM_MEMBERS_SUCCESS = `${PREFIX} FETCH_TEAM_MEMBERS_SUCCESS`;
export const FETCH_TEAM_MEMBERS_FAILURE = `${PREFIX} FETCH_TEAM_MEMBERS_FAILURE`;

const fetchTeamMembersRequest = () => ({
  type: FETCH_TEAM_MEMBERS_REQUEST,
});

const fetchTeamMembersSuccess = payload => ({
  payload,
  type: FETCH_TEAM_MEMBERS_SUCCESS,
});

const fetchTeamMembersFailure = payload => ({
  payload,
  type: FETCH_TEAM_MEMBERS_FAILURE,
});

export const fetchTeamMembers = organizationUuid => dispatch => {
  dispatch(fetchTeamMembersRequest());

  return request
    .get(`/v1/accounts/organization_users/?organization=${organizationUuid}`)
    .then(({ data }) => {
      dispatch(fetchTeamMembersSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTeamMembersFailure(data));

      throw data;
    });
};

export const DELETE_INVITATION_REQUEST = `${PREFIX} DELETE_INVITATION_REQUEST`;
export const DELETE_INVITATION_SUCCESS = `${PREFIX} DELETE_INVITATION_SUCCESS`;
export const DELETE_INVITATION_FAILURE = `${PREFIX} DELETE_INVITATION_FAILURE`;

const deleteInvitationRequest = () => ({
  type: DELETE_INVITATION_REQUEST,
});

const deleteInvitationSuccess = payload => ({
  payload,
  type: DELETE_INVITATION_SUCCESS,
});

const deleteInvitationFailure = payload => ({
  payload,
  type: DELETE_INVITATION_FAILURE,
});

export const deleteInvitation = (organizationUuid, invitationUuid) => dispatch => {
  dispatch(deleteInvitationRequest());

  return request
    .delete(`/v1/accounts/organizations/${organizationUuid}/invitations/${invitationUuid}/`)
    .then(({ data }) => {
      dispatch(deleteInvitationSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteInvitationFailure(data));

      throw data;
    });
};

export const POST_REMIND_INVITATION_REQUEST = `${PREFIX} POST_REMIND_INVITATION_REQUEST`;
export const POST_REMIND_INVITATION_SUCCESS = `${PREFIX} POST_REMIND_INVITATION_SUCCESS`;
export const POST_REMIND_INVITATION_FAILURE = `${PREFIX} POST_REMIND_INVITATION_FAILURE`;

const postRemindInvitationRequest = () => ({
  type: POST_REMIND_INVITATION_REQUEST,
});

const postRemindInvitationSuccess = payload => ({
  payload,
  type: POST_REMIND_INVITATION_SUCCESS,
});

const postRemindInvitationFailure = payload => ({
  payload,
  type: POST_REMIND_INVITATION_FAILURE,
});

export const postRemindInvitation = (organizationUuid, inviteUuid) => dispatch => {
  dispatch(postRemindInvitationRequest());

  return request
    .post(`/v1/accounts/organizations/${organizationUuid}/invitations/${inviteUuid}/remind/`)
    .then(({ data }) => {
      dispatch(postRemindInvitationSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postRemindInvitationFailure(data));

      throw data;
    });
};

export const FETCH_API_KEYS_REQUEST = `${PREFIX} FETCH_API_KEYS_REQUEST`;
export const FETCH_API_KEYS_SUCCESS = `${PREFIX} FETCH_API_KEYS_SUCCESS`;
export const FETCH_API_KEYS_FAILURE = `${PREFIX} FETCH_API_KEYS_FAILURE`;

const fetchApiKeysRequest = () => ({
  type: FETCH_API_KEYS_REQUEST,
});

const fetchApiKeysSuccess = payload => ({
  payload,
  type: FETCH_API_KEYS_SUCCESS,
});

const fetchApiKeysFailure = payload => ({
  payload,
  type: FETCH_API_KEYS_FAILURE,
});

export const fetchApiKeys = organizationUuid => dispatch => {
  dispatch(fetchApiKeysRequest());

  return request
    .get(`v1/accounts/organizations/${organizationUuid}/api_keys/`)
    .then(({ data }) => {
      dispatch(fetchApiKeysSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchApiKeysFailure(data));

      throw data;
    });
};

export const POST_API_KEY_REQUEST = `${PREFIX} POST_API_KEY_REQUEST`;
export const POST_API_KEY_SUCCESS = `${PREFIX} POST_API_KEY_SUCCESS`;
export const POST_API_KEY_FAILURE = `${PREFIX} POST_API_KEY_FAILURE`;

const postApiKeyRequest = () => ({
  type: POST_API_KEY_REQUEST,
});

const postApiKeySuccess = payload => ({
  payload,
  type: POST_API_KEY_SUCCESS,
});

const postApiKeyFailure = payload => ({
  payload,
  type: POST_API_KEY_FAILURE,
});

export const postApiKey = (organizationUuid, params) => dispatch => {
  dispatch(postApiKeyRequest());

  return request
    .post(`/v1/accounts/organizations/${organizationUuid}/api_keys/`, params)
    .then(({ data }) => {
      dispatch(postApiKeySuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postApiKeyFailure(data));

      throw data;
    });
};

export const DELETE_API_KEY_REQUEST = `${PREFIX} DELETE_API_KEY_REQUEST`;
export const DELETE_API_KEY_SUCCESS = `${PREFIX} DELETE_API_KEY_SUCCESS`;
export const DELETE_API_KEY_FAILURE = `${PREFIX} DELETE_API_KEY_FAILURE`;

const deleteApiKeyRequest = () => ({
  type: DELETE_API_KEY_REQUEST,
});

const deleteApiKeySuccess = payload => ({
  payload,
  type: DELETE_API_KEY_SUCCESS,
});

const deleteApiKeyFailure = payload => ({
  payload,
  type: DELETE_API_KEY_FAILURE,
});

export const deleteApiKey = (organizationUuid, apiKeyUuid) => dispatch => {
  dispatch(deleteApiKeyRequest());

  return request
    .delete(`/v1/accounts/organizations/${organizationUuid}/api_keys/${apiKeyUuid}/`)
    .then(({ data }) => {
      dispatch(deleteApiKeySuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteApiKeyFailure(data));

      throw data;
    });
};

export const PATCH_ACTION_ALERT_REQUEST = `${PREFIX} PATCH_ACTION_ALERT_REQUEST`;
export const PATCH_ACTION_ALERT_SUCCESS = `${PREFIX} PATCH_ACTION_ALERT_SUCCESS`;
export const PATCH_ACTION_ALERT_FAILURE = `${PREFIX} PATCH_ACTION_ALERT_FAILURE`;

const patchActionAlertRequest = () => ({
  type: PATCH_ACTION_ALERT_REQUEST,
});

const patchActionAlertSuccess = payload => ({
  payload,
  type: PATCH_ACTION_ALERT_SUCCESS,
});

const patchActionAlertFailure = payload => ({
  payload,
  type: PATCH_ACTION_ALERT_FAILURE,
});

export const patchActionAlert = (organizationUuid, apiKeyUuid, params) => dispatch => {
  dispatch(patchActionAlertRequest());

  return request
    .patch(`/v1/accounts/organizations/${organizationUuid}/api_keys/${apiKeyUuid}/`, params)
    .then(({ data }) => {
      dispatch(patchActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_EMAIL_DOMAINS_REQUEST = `${PREFIX} FETCH_EMAIL_DOMAINS_REQUEST`;
export const FETCH_EMAIL_DOMAINS_SUCCESS = `${PREFIX} FETCH_EMAIL_DOMAINS_SUCCESS`;
export const FETCH_EMAIL_DOMAINS_FAILURE = `${PREFIX} FETCH_EMAIL_DOMAINS_FAILURE`;

const fetchEmailDomainsRequest = () => ({
  type: FETCH_EMAIL_DOMAINS_REQUEST,
});

const fetchEmailDomainsSuccess = payload => ({
  payload,
  type: FETCH_EMAIL_DOMAINS_SUCCESS,
});

const fetchEmailDomainsFailure = payload => ({
  payload,
  type: FETCH_EMAIL_DOMAINS_FAILURE,
});

export const fetchEmailDomains = organizationUuid => dispatch => {
  dispatch(fetchEmailDomainsRequest());

  return request
    .get(`/v1/accounts/email-domains/?organization=${organizationUuid}`)
    .then(({ data }) => {
      dispatch(fetchEmailDomainsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchEmailDomainsFailure(data));

      throw data;
    });
};

export const POST_EMAIL_DOMAINS_REQUEST = `${PREFIX} POST_EMAIL_DOMAINS_REQUEST`;
export const POST_EMAIL_DOMAINS_SUCCESS = `${PREFIX} POST_EMAIL_DOMAINS_SUCCESS`;
export const POST_EMAIL_DOMAINS_FAILURE = `${PREFIX} POST_EMAIL_DOMAINS_FAILURE`;

const postEmailDomainsRequest = () => ({
  type: POST_EMAIL_DOMAINS_REQUEST,
});

const postEmailDomainsSuccess = payload => ({
  payload,
  type: POST_EMAIL_DOMAINS_SUCCESS,
});

const postEmailDomainsFailure = payload => ({
  payload,
  type: POST_EMAIL_DOMAINS_FAILURE,
});

export const postEmailDomains = params => dispatch => {
  dispatch(postEmailDomainsRequest());

  return request
    .post('/v1/accounts/email-domains/', params)
    .then(({ data }) => {
      dispatch(postEmailDomainsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postEmailDomainsFailure(data));

      throw data;
    });
};

export const DELETE_EMAIL_DOMAIN_REQUEST = `${PREFIX} DELETE_EMAIL_DOMAIN_REQUEST`;
export const DELETE_EMAIL_DOMAIN_SUCCESS = `${PREFIX} DELETE_EMAIL_DOMAIN_SUCCESS`;
export const DELETE_EMAIL_DOMAIN_FAILURE = `${PREFIX} DELETE_EMAIL_DOMAIN_FAILURE`;

const deleteEmailDomainRequest = () => ({
  type: DELETE_EMAIL_DOMAIN_REQUEST,
});

const deleteEmailDomainSuccess = payload => ({
  payload,
  type: DELETE_EMAIL_DOMAIN_SUCCESS,
});

const deleteEmailDomainFailure = payload => ({
  payload,
  type: DELETE_EMAIL_DOMAIN_FAILURE,
});

export const deleteEmailDomain = domainUuid => dispatch => {
  dispatch(deleteEmailDomainRequest());

  return request
    .delete(`/v1/accounts/email-domains/${domainUuid}`)
    .then(({ data }) => {
      dispatch(deleteEmailDomainSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteEmailDomainFailure(data));

      throw data;
    });
};
