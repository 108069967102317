import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  Publish as PublishIcon,
  Store as StoreIcon,
} from '@mui/icons-material';

import { fetchCampaigns } from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';

import {
  AddCampaignDialog,
  Container,
  DescriptionBar,
  LinkButton,
} from 'common/components';

import {
  CircularProgressWrapper,
  StyledCode,
} from 'globalStyles';
import { useCopyToClipboard } from 'utils/hooks';
import {
  EXPORT_ADVOCATES_SUCCESS_MESSAGE,
  exportAdvocates,
} from '../../../store/actions/crm';
import { setSnackbarSuccess } from '../../../store/actions/global';
import { organizationSelectors } from '../../../store/selectors/organizations';
import WidgetDialog from '../../ActionCenterDetails/components/WidgetDialog';

import CampaignItem from '../components/CampaignItem';

const CampaignWrapper = styled(Grid)`
  width: 100%;
  padding-top: 30px;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-right: 20px;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

const Campaign = () => {
  const dispatch = useDispatch();
  const [copy] = useCopyToClipboard();

  const { organizationUuid } = useParams();

  const {
    campaignsList,
    isCampaignsFetching,
    organization,
  } = useSelector(state => ({
    campaignsList: campaignsSelectors.getCampaignsListData(state),
    isCampaignsFetching: campaignsSelectors.isFetching(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  const [
    isCampaignDialogOpen,
    setIsCampaignDialogOpen,
  ] = useState(false);
  const [
    isWidgetModalOpen,
    setIsWidgetModalOpen,
  ] = useState(false);
  const [
    pagination,
    setPagination,
  ] = useState(10);

  useEffect(() => {
    dispatch(fetchCampaigns({ organization: organizationUuid }));
  }, [
    organizationUuid,
    dispatch,
  ]);

  const handleSnackbarOpen = () => {
    dispatch(exportAdvocates(organizationUuid))
    .then(() => dispatch(setSnackbarSuccess({ message: EXPORT_ADVOCATES_SUCCESS_MESSAGE })));
  };

  const handleCloseModal = () => {
    setIsCampaignDialogOpen(false);
    dispatch(fetchCampaigns({ organization: organizationUuid }));
  };

  if (isCampaignsFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  const {
    REACT_APP_LANDING_PAGE_URL,
    REACT_APP_LIST_WIDGET_URL,
  } = process.env;
  const widgetCode = `
    <div
      data-org-id="${organizationUuid}"
      data-domain="${REACT_APP_LANDING_PAGE_URL || 'https://mstr.app'}"
      data-height="500"
      data-width="455"
      id="muster-list"
    >
      <script src="${REACT_APP_LIST_WIDGET_URL || 'https://widgets.muster.com/v2/list-action-centers.js'}"></script>
    </div>
  `;

  const handleCopyToClipboard = () => {
    copy(widgetCode, 'textarea', 'Code');
    setIsCampaignDialogOpen(false);
  };

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          icon={StoreIcon}
          name="Advocacy Campaigns"
        >
          <StyledLinkButton
            name="New Campaign"
            to={`/${organizationUuid}/create-action-center`}
            variant="newItem"
          />
          <Button
            onClick={() => setIsWidgetModalOpen(true)}
            style={{ marginRight: '20px' }}
            variant="outlined"
          >
            List Widget
          </Button>
          <Button
            onClick={handleSnackbarOpen}
            startIcon={<PublishIcon />}
            variant="outlined"
          >
            Export advocates
          </Button>
        </DescriptionBar>
      </Grid>
      <AddCampaignDialog
        handleDialogClose={handleCloseModal}
        isDialogOpen={isCampaignDialogOpen}
        subtitle="Campaign Name"
        title="Create a Campaign"
      />
      <CampaignWrapper>
        {!!campaignsList.length && campaignsList.map(({
          createdAt,
          createdBy,
          name,
          statistics,
          uuid,
        }) => (
          <CampaignItem
            createdAt={createdAt}
            createdBy={createdBy}
            key={uuid}
            name={name}
            showPetitions={organization?.flags?.showPetitions}
            statistics={statistics}
            uuid={uuid}
          />
        ))}
      </CampaignWrapper>
      <WidgetDialog
        isDialogOpen={isWidgetModalOpen}
        handleDialogClose={() => setIsWidgetModalOpen(false)}
        title="Embed a list of live action centers"
        handleCopyToClipboard={handleCopyToClipboard}
      >
        <>
          <StyledCode>
            {widgetCode}
          </StyledCode>
          <p>
            The code above can be embedded into your own website to list
            and link to your live action centers. They will be titled
            using the main headline.
          </p>
        </>
      </WidgetDialog>
      <LoadMoreWrapper>
        {
          campaignsList?.length > pagination && (
            <Button
              onClick={() => setPagination(prevValue => prevValue + 10)}
              variant="outlined"
            >
              Load More
            </Button>
          )
        }
      </LoadMoreWrapper>
    </Container>
  );
};

export default Campaign;
