import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  deleteApiKey,
  fetchApiKeys,
  patchActionAlert,
} from 'store/actions/organizations';
import { setSnackbarSuccess } from 'store/actions/global';

import { TableIcon } from 'common/components';

import ApiKeyForm from '../ApiKeyForm';

const StyledTableCell = styled(TableCell)`
  width: 25%;
  padding-left: 0;
`;

const ApiKeysTable = ({
  apiKeysData,
  organizationUuid,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [
    apiKeyDataToEdit,
    setApiKeyDataToEdit,
  ] = useState({
    isDialogOpen: false,
  });

  const handleDeleteApiKey = uuid => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected API Key',
      title: 'Delete selected API Key',
    })
      .then(() => dispatch(deleteApiKey(organizationUuid, uuid)))
      .then(() => dispatch(fetchApiKeys(organizationUuid)));
  };

  const onEditClick = apiKeyData => {
    setApiKeyDataToEdit({
      isDialogOpen: true,
      ...apiKeyData,
    });
  };

  const handleUpdateApiKey = async event => {
    const params = {
      isActive: event.isActive,
      name: event.name,
    };

    await dispatch(patchActionAlert(organizationUuid, event.uuid, params));
    await dispatch(fetchApiKeys(organizationUuid));

    setApiKeyDataToEdit({ isDialogOpen: false });
    dispatch(setSnackbarSuccess({ message: 'Correctly updated api key data' }));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>is Active</StyledTableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeysData.map(apiKeyData => (
            <TableRow key={apiKeyData.name}>
              <StyledTableCell>{apiKeyData.name}</StyledTableCell>
              <StyledTableCell>
                <TableIcon boolean={apiKeyData.isActive} />
              </StyledTableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditClick(apiKeyData)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteApiKey(apiKeyData.uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ApiKeyForm
        data={apiKeyDataToEdit}
        handleDialogClose={() => setApiKeyDataToEdit({ isDialogOpen: false })}
        onSubmit={handleUpdateApiKey}
      />
    </TableContainer>
  );
};

ApiKeysTable.defaultProps = {
  apiKeysData: [],
};

ApiKeysTable.propTypes = {
  apiKeysData: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      name: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  organizationUuid: PropTypes.string.isRequired,
};

export default ApiKeysTable;
