import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/material';

import { colors } from 'utils/constants';
import { statsColor } from 'utils/helpers';

const LinearProgressLabel = styled.span`
  position: absolute;
  bottom: 8px;
  left: 5px;
  color: ${props => (props.statscolor || '#426ABB')};
  font-size: 12px;
`;

const StyledCard = styled(Card)`
  position: relative;
  min-width: 180px;
  max-width: 270px;
  height: 170px;
  padding: 10px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  text-align: center;

  &:last-child {
    padding-bottom: 0;
  }
`;

const StyledCount = styled(Typography)`
  font-size: 64px;
  line-height: 77px;
  letter-spacing: 0;
`;

const StyledLinearProgress = styled(LinearProgress)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: ${colors.wildSand};

  /* stylelint-disable-next-line selector-class-pattern */
  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => (props.statscolor || colors.primary)};
  }
`;

const StatisticCard = ({
  count,
  hasPercentageBar,
  isPrimaryColor,
  isOutlined,
  maxCount,
  text,
}) => {
  const percentageValue = (count / maxCount) * 100 || 0;

  return (
    <StyledCard variant={isOutlined ? 'outlined' : 'elevation'}>
      <StyledCardContent>
        <StyledCount color={isPrimaryColor ? 'primary' : 'secondary'}>{count}</StyledCount>
        <Typography variant="subtitle1">{text}</Typography>
        {hasPercentageBar && (
          <>
            <LinearProgressLabel
              statscolor={statsColor(text)}
              variant="subtitle2"
            >
              {percentageValue.toFixed(1)}
              %
            </LinearProgressLabel>
            <StyledLinearProgress
              statscolor={statsColor(text)}
              value={percentageValue}
              variant="determinate"
            />
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

StatisticCard.defaultProps = {
  count: 0,
  hasPercentageBar: false,
  isOutlined: false,
  isPrimaryColor: true,
  maxCount: 0,
  text: '',
};

StatisticCard.propTypes = {
  count: PropTypes.number,
  hasPercentageBar: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isPrimaryColor: PropTypes.bool,
  maxCount: PropTypes.number,
  text: PropTypes.string,
};

export default StatisticCard;
