import { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import {
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  Launch as LaunchIcon,
  Link as LinkIcon,
} from '@mui/icons-material';

import {
  copyActionCenter,
  deleteActionCenter,
  fetchActionCenter,
  patchActionCenter,
} from 'store/actions/campaigns';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { campaignsSelectors } from 'store/selectors/campaigns';

import {
  ActionAlertMenuButton,
} from 'common/components';

import {
  LIVE_PUBLISH_STATUS,
  STOPPED_PUBLISH_STATUS,
  TEST_MODE_PUBLISH_STATUS,
} from 'utils/constants';
import {
  nameOfPublishStatus,
  showFirstErrorMessage,
} from 'utils/helpers';
import { useCopyToClipboard } from 'utils/hooks';

import TopCard from 'common/containers/TopCard';

const chipColor = value => {
  switch (value) {
    case 1:
      return 'grey';
    case 2:
      return 'green';
    case 3:
      return 'error';
    case 4:
      return 'blue';
    default:
      return null;
  }
};

const formatDate = date => {
  const options = {
    day: '2-digit',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
    month: 'long',
    second: '2-digit',
    year: 'numeric',
  };

  return new Date(date).toLocaleString('en-US', options);
};

const { REACT_APP_LANDING_PAGE_URL } = process.env;

const TopCardWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const [copy] = useCopyToClipboard();

  const {
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();

  const {
    actionCenterDetails,
  } = useSelector(state => ({
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
  }));

  const {
    canDelete,
    canStop,
    createdAt,
    createdBy,
    mode,
    name,
    isGeocoded,
    publishStatus,
    uuid,
  } = actionCenterDetails;

  const handleEnableTestMode = () => {
    confirm({
      confirmationText: 'Test mode',
      description: 'Entering test mode will disable sending messages or collecting contact information and exclude this action center from your active campaign widget.',
      title: 'Do you want to enable test mode on this action center?',
    })
    .then(() => dispatch(patchActionCenter(
      actionCenterUuid,
      { publishStatus: TEST_MODE_PUBLISH_STATUS }
    )))
    .then(() => dispatch(fetchActionCenter(actionCenterUuid)))
    .catch(error => {
      if (error) {
        dispatch(setSnackbarError({
          message: showFirstErrorMessage(error),
        }));
      }
    });
  };

  const handleStopActionCenter = () => {
    confirm({
      confirmationText: 'Stop',
      description: 'Stopping the action center will unpublish the landing page and stop sending new messages to targeted officials',
      title: 'Do you want to stop this action center?',
    })
      .then(() => dispatch(patchActionCenter(
        actionCenterUuid,
        { publishStatus: STOPPED_PUBLISH_STATUS }
      )))
      .then(() => dispatch(fetchActionCenter(actionCenterUuid)))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  const handleDuplicateActionCenter = () => {
    dispatch(copyActionCenter(actionCenterUuid))
    .then(data => {
      dispatch(fetchActionCenter(actionCenterUuid));
      dispatch(setSnackbarSuccess({ message: 'Action Center copied!' }));
      history.push(`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${data.uuid}`);
    })
    .catch(error => {
      if (error) {
        dispatch(setSnackbarError({
          message: showFirstErrorMessage(error),
        }));
      }
    });
  };

  const handleDisableTestMode = () => {
    confirm({
      confirmationText: 'Go live',
      description: 'Turning off test mode will enable sending messages and collecting contact information, and include this action center in your active campaign widget.',
      title: 'Do you want to stop testing and enable this action center?',
    })
    .then(() => dispatch(patchActionCenter(
      actionCenterUuid,
      { publishStatus: LIVE_PUBLISH_STATUS }
    )))
    .then(() => dispatch(fetchActionCenter(actionCenterUuid)))
    .catch(error => {
      if (error) {
        dispatch(setSnackbarError({
          message: showFirstErrorMessage(error),
        }));
      }
    });
  };

  const handleDeleteActionCenter = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'You won`t be able to undo that',
      title: 'Do you want to delete this action center?',
    })
      .then(() => dispatch(deleteActionCenter(actionCenterUuid)))
      .then(() => dispatch(setSnackbarSuccess({ message: 'Action Center has been removed' })))
      .then(() => history.push(`/${organizationUuid}/campaigns/${campaignUuid}`))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  const handleEditActionCenter = () => history.push(`/${organizationUuid}/${actionCenterUuid}/create-action-center/${campaignUuid}`);

  const topCardData = useCallback(
    () => {
      const chipOptions = () => {
        const options = [];

        if (publishStatus === LIVE_PUBLISH_STATUS) {
          options.push({
            disabled: !canStop,
            label: 'Test Mode',
            tooltipText: !canStop ? 'Cancel scheduled action alerts before enabling test mode.' : '',
            value: TEST_MODE_PUBLISH_STATUS,
          });
        }

        if (publishStatus === TEST_MODE_PUBLISH_STATUS) {
          options.push({
            label: 'Live',
            tooltipText: 'Go Live (Disable Test Mode)',
            value: LIVE_PUBLISH_STATUS,
          });
        }

        if (
          publishStatus === LIVE_PUBLISH_STATUS ||
          publishStatus === TEST_MODE_PUBLISH_STATUS
        ) {
          options.push({
            label: 'Stopped',
            tooltipText: !canStop ? 'Cancel scheduled action alerts before stopping this action center.' : '',
            value: STOPPED_PUBLISH_STATUS,
          });
        }

        return options;
      };

      const chipData = {
        chipColor: chipColor(publishStatus),
        chipLabel: nameOfPublishStatus(publishStatus),
        chipOptions: chipOptions(),
        chipOptionsHeader: 'Change status',
        chipType: '',
        handleSelectChipValue: value => {
          switch (value) {
            case LIVE_PUBLISH_STATUS:
              return handleDisableTestMode();
            case STOPPED_PUBLISH_STATUS:
              return handleStopActionCenter();
            case TEST_MODE_PUBLISH_STATUS:
              return handleEnableTestMode();
            default:
              return null;
          }
        },
      };

      const actionButtons = [
        {
          color: 'grey',
          name: 'Edit',
          onClick: handleEditActionCenter,
          startIcon: <EditOutlinedIcon />,
        },
        {
          color: 'grey',
          name: 'Duplicate',
          onClick: handleDuplicateActionCenter,
          startIcon: <ContentCopyOutlinedIcon />,
        },
        {
          color: 'grey',
          disabled: publishStatus === LIVE_PUBLISH_STATUS || !canDelete,
          name: 'Delete',
          onClick: handleDeleteActionCenter,
          startIcon: <DeleteOutlineOutlinedIcon />,
          tooltipText: publishStatus === 2 && 'You cannot delete an Action Center if it is live',
        },
      ];

      const additionalActionButtons = [];

      if (publishStatus === LIVE_PUBLISH_STATUS || publishStatus === TEST_MODE_PUBLISH_STATUS) {
        additionalActionButtons.push({
          color: 'primary',
          endIcon: <LaunchIcon />,
          href: `${REACT_APP_LANDING_PAGE_URL}/${uuid}`,
          name: 'Open Landing Page',
          rel: 'noopener noreferrer',
          target: '_blank',
        });
      }

      additionalActionButtons.push({
        color: 'grey',
        endIcon: <LinkIcon />,
        name: 'Copy landing link',
        onClick: () => copy(`${REACT_APP_LANDING_PAGE_URL}/${uuid}`),
      });

      return ({
        actionButtons,
        additionalActionButtons,
        backButtonLink: `/${organizationUuid}/campaigns/${campaignUuid}`,
        chipData,
        dateInfo: `${formatDate(createdAt)} ${createdBy && `by ${createdBy.firstName} ${createdBy.lastName}`}`,
        details: [],
        entityTypes: [
          `${mode === 'petition' ? 'Petition' : 'Send Messages'}`,
          `${isGeocoded ? 'Geotargeted' : 'Non-geotargeted'}`,
        ],
        restActions: (
          <ActionAlertMenuButton
            actionCenterUuid={actionCenterUuid}
            campaignUuid={campaignUuid}
            isDisabled={publishStatus !== LIVE_PUBLISH_STATUS}
          />
        ),
        title: name || '',
      });
    },
    [
      createdAt,
      isGeocoded,
      name,
      publishStatus,
    ]
  );

  return (
    <TopCard {...topCardData()} />
  );
};

export default TopCardWrapper;
