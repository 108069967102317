import {
  Field,
  Form,
} from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  deleteInvitation,
  fetchInvitations,
  postRemindInvitation,
} from 'store/actions/organizations';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  DeleteOutline as DeleteOutlineIcon,
  EmailOutlined as EmailOutlineIcon,
} from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';

import { required } from 'utils/validators';
import {
  Bold,
  FormWrapper,
  SettingsWrapper,
} from 'globalStyles';

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const RemindButton = styled(Button)`
  font-weight: 400;
  text-transform: lowercase;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 20px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const MembersList = styled.div`
  width: 50%;
  min-width: 600px;
  margin: 20px 0 40px;
`;

const DescriptiveHelpText = styled(Paper)`
  width: 50%;
  min-width: 600px;
  padding: 10px;
  text-align: left;
`;

const MusterIcon = styled.img`
  max-height: 20px;
`;

const INVITE_ADMIN_SUCCESS_MESSAGE = 'Your invitation has been sent.';
const INVITE_ADMIN = 'Send invitation';
const INVITE_INFO_TITLE = 'Invite a team member to join you on Muster';
const TEAM_MANAGEMENT = 'Team members and invitations';
const CONFIRM_REMIND_INVITATION = 'This will send a new reminder email with the same invitation link as previously sent.';
const CONFIRM_DELETE_INVITATION = 'Any previous invitations to this email will no longer work. If you want to resend, simply send a reminder instead.';

const AdminTab = ({
  onInvite,
  logo,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();
  const confirm = useConfirm();

  const {
    invitedMembers,
    teamMembers,
  } = useSelector(state => ({
    invitedMembers: organizationSelectors.getInvitedMembers(state),
    teamMembers: organizationSelectors.getTeamMembers(state),
  }));

  const handleRemoveInvitation = invitedMember => {
    confirm({
      confirmationText: 'Remove',
      description: CONFIRM_DELETE_INVITATION,
      title: 'Remove the Invitation?',
    })
      .then(() => dispatch(deleteInvitation(organizationUuid, invitedMember.uuid)))
      .then(() => dispatch(fetchInvitations(organizationUuid)));
  };

  const handleRemindInvitation = invitedMember => {
    confirm({
      confirmationText: 'Remind',
      description: CONFIRM_REMIND_INVITATION,
      title: 'Remind the Invitation?',
    })
      .then(() => dispatch(postRemindInvitation(organizationUuid, invitedMember.uuid)))
      .then(() => dispatch(fetchInvitations(organizationUuid)))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: error.message,
          }));
        }
      });
  };

  return (
    <SettingsWrapper>
      <Grid>
        <Typography variant="h6">{TEAM_MANAGEMENT}</Typography>
        <DescriptiveHelpText>
          <Typography variant="body2">
            Current team members and pending invitations are listed below.
            If a team member still has not accepted your invitation
            you can send them a reminder with the email links below.
          </Typography>
        </DescriptiveHelpText>
        <MembersList>
          <Table
            size="medium"
            aria-label="team table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="left">Admin</TableCell>
                <TableCell alight="right">Email</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers?.map(teamMember => (
                <TableRow key={teamMember.uuid}>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <Bold>
                      {teamMember.user.firstName}
                      {' '}
                      {teamMember.user.lastName}
                    </Bold>
                  </TableCell>
                  <TableCell align="center">
                    <CheckCircleOutlineIcon color="secondary" />
                  </TableCell>
                  <TableCell>
                    {teamMember.isAdmin ? <span>☑️</span> : <span />}
                  </TableCell>
                  <TableCell>{teamMember.user.email}</TableCell>
                  <TableCell align="center">{teamMember.isMuster ? <MusterIcon src="/apple-touch-icon.png" /> : <MusterIcon src={logo} />}</TableCell>
                </TableRow>
              ))}
              {invitedMembers.results?.map(invitedMember => (
                <TableRow key={invitedMember.uuid}>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    Invited user
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="remind"
                      onClick={() => handleRemindInvitation(invitedMember)}
                      size="large"
                    >
                      <EmailOutlineIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {invitedMember.isAdmin ? <span>☑️</span> : <span />}
                  </TableCell>
                  <TableCell align="left">
                    <RemindButton
                      disableElevation
                      onClick={() => handleRemindInvitation(invitedMember)}
                    >
                      {invitedMember.email}
                    </RemindButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveInvitation(invitedMember)}
                      size="large"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MembersList>
      </Grid>
      <Grid container>
        <FormWrapper>
          <Typography variant="h6">{INVITE_INFO_TITLE}</Typography>
          <StyledDivider />
          <Form
            onSubmit={onInvite}
            render={({
              form,
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={async event => {
                const response = await handleSubmit(event);

                if (response === 'success') {
                  dispatch(setSnackbarSuccess({ message: INVITE_ADMIN_SUCCESS_MESSAGE }));

                  form.restart();
                }
              }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                  >
                    <StyledField
                      name="email"
                      validate={required}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="Email"
                          type="email"
                          variant="standard"
                        />
                      )}
                    </StyledField>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="space-between"
                  container
                  item
                  xs={12}
                >
                  <Field
                    name="is_admin"
                    type="checkbox"
                  >
                    {({ input }) => (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            {...input}
                            color="primary"
                          />
                        )}
                        label="Grant admin role (access account settings, invite team members)"
                      />
                    )}
                  </Field>
                </Grid>
                <StyledGrid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                >
                  <StyledButton
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    {INVITE_ADMIN}
                  </StyledButton>
                </StyledGrid>
              </form>
            )}
          />
        </FormWrapper>
      </Grid>
    </SettingsWrapper>
  );
};

AdminTab.defaultProps = {
  logo: null,
};

AdminTab.propTypes = {
  logo: PropTypes.string,
  onInvite: PropTypes.func.isRequired,
};

export default AdminTab;
