import { useLocation } from 'react-router-dom';
import {
  get,
  set,
} from 'lodash';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Chip } from '@mui/material';

import {
  broadcastTemplate1PathToOrgName,
  broadcastTemplate1PathToSocials,
  broadcastTemplate2PathToOrgData,
  broadcastTemplate2PathToSocials,
  colors,
  statuses,
  template1PathToOrgName,
  template1PathToSocials,
  template2PathToOrgData,
  template2PathToSocials,
} from 'utils/constants';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const defaultError = 'There was an unexpected error! Please check your submission and try again.';

export const formatPhoneNumber = phoneNumberString => {
  if (phoneNumberString) {
    const match = phoneNumberString.substring(0, 10).match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}${phoneNumberString.substring(10)}`;
    }

    return phoneNumberString;
  }

  return null;
};

export const saveFile = (uri, filename) => {
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

export const fullOfficialName = (official, title) => (
  `${official.firstName} ${official.middleName} ${official.lastName}, ${title}`
);

export const isEmail = value => (
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
);

export const youtubeParser = url => {
  if (url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[7].length === 11) ?
      match[7] :
      false;
  }

  return false;
};

export const isInPreviousDays = (date, numberOfDays) => {
  const previousDate = new Date();

  previousDate.setDate(previousDate.getDate() - numberOfDays);

  return new Date(previousDate).getTime() <= new Date(date).getTime();
};

export const isValidURL = url => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return !!pattern.test(url);
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const statsColor = type => {
  switch (type) {
    case 'Opened':
    case 'Clicked':
    case 'Actions Taken':
      return colors.secondary;
    case 'Bounced':
    case 'Unsubscribed':
      return colors.warning;
    default:
      return null;
  }
};

export const handleNumberFormat = value => {
  const digitsPlus = value.replace(/[^+0-9]/g, '');

  const leadingDigitRegex = /^\+1/;
  const hasLeadingDigit = digitsPlus.match(leadingDigitRegex);
  const withoutLeadingDigit = digitsPlus.replace(leadingDigitRegex, '');

  if (withoutLeadingDigit.length < 10) {
    return digitsPlus;
  }

  return formatPhoneNumber(hasLeadingDigit ? withoutLeadingDigit : digitsPlus);
};

/**
* searches deep into an object recursively...
* @param {Object} obj object to be searched
* @param {any} searchValue the value/key to search for
* @param {Object} [options]
* @param {boolean} options.[searchKeys] whether to search object keys as well as values.
  Defaults to `true` if `serchValue` is a string, `false` otherwise.
* @param {number} options.[maxDepth=20] maximum recursion depth
  (to avoid "Maximum call stack size exceeded")
* @returns {string[]} Paths on the object to the matching results
*/

export const findPaths = (
  obj,
  searchValue,
  {
    searchKeys = typeof searchValue === 'string',
    maxDepth = 20,
  } = {}
) => {
  const paths = [];
  const createPaths = (object, maximumDepth, prefix) => {
    if (!maximumDepth) {
      return;
    }

    if (!object) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [
      curr,
      currElem,
    ] of Object.entries(object)) {
      if (searchKeys && curr === searchValue) {
        paths.push(`${prefix}.${curr}`);
      }

      if (typeof currElem === 'object') {
        // eslint-disable-next-line no-restricted-globals
        const element = isNaN(curr) ? `${prefix && `${prefix}.`}${curr}` : `${prefix}[${curr}]`;

        createPaths(currElem, maximumDepth - 1, element);
      }

      if (currElem === searchValue) {
        paths.push(`${prefix}.${curr}`);
      }
    }
  };

  createPaths(obj, maxDepth, '');

  return paths;
};

export const nameOfPublishStatus = value => {
  switch (value) {
    case 1:
      return 'Draft';
    case 2:
      return 'Live';
    case 3:
      return 'Stopped';
    case 4:
      return 'Test Mode';
    default:
      return null;
  }
};

export const disableLink = (url, flags) => {
  switch (url) {
    case 'contacts/lists':
      return !flags?.showLists;
    case 'campaigns':
      return !flags?.showCampaigns;
    case 'broadcasts':
      return !flags?.showBroadcasts;
    default:
      return false;
  }
};

export const isProductRoute = (location, product) => {
  switch (product) {
    case 'broadcasts':
      return location?.pathname?.includes('broadcast');
    case 'advocacy':
      return location?.pathname?.includes('campaigns') || location?.pathname?.includes('target-groups') || location?.pathname?.includes('alert');
    case 'lists':
      return location?.pathname?.includes('contacts') || location?.pathname?.includes('signups') || location?.pathname?.includes('custom-fields');
    default:
      return false;
  }
};

export const handleOnlyNumbers = value => {
  if (!value) {
    return value;
  }

  return value.replace(/\D/g, '');
};

export const handleZipcodeFormat = value => {
  if (!value) {
    return value;
  }

  const onlyNums = handleOnlyNumbers(value);

  if (onlyNums.length < 9) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
};

export const formatNumber = number => number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const fullLocationsName = list => `
  ${list[0].name}
  ${list[1]?.name ? `or ${list[1]?.name}` : ''}
  ${list[2]?.name ? 'or ...' : ''}
`;

export const scrollToTop = () => window && window.scrollTo({
  behavior: 'smooth',
  top: 0,
});

export const scrollToBottom = () => window && window.scrollTo({
  behavior: 'smooth',
  top: document.documentElement.scrollHeight,
});

const WarningChip = styled(Chip)`
  background-color: red;
`;

export const renderChip = (status, sendAtUtc, icon) => {
  const {
    draft,
    published,
  } = statuses;

  if (status === published) {
    const sent = (new Date().getTime() - new Date(sendAtUtc).getTime()) > 0;

    return (
      <Chip
        color="secondary"
        icon={icon}
        label={sent ? 'Sent' : 'Scheduled'}
      />
    );
  }

  if (status === draft) {
    return (
      <WarningChip
        color="primary"
        icon={icon}
        label="Draft"
      />
    );
  }

  return null;
};

const ErrorDescription = styled.span`
  display: block;
`;

export const showFirstErrorMessage = errorMessage => {
  if (typeof errorMessage === 'object' &&
    errorMessage !== null
  ) {
    const firstError = errorMessage[Object.keys(errorMessage)[0]];

    if (typeof firstError === 'string' && !firstError.includes('<html')) {
      return firstError;
    }

    if (Array.isArray(firstError)) {
      if (typeof firstError[0] === 'string') {
        return firstError[0];
      }
    }

    if (typeof firstError === 'object') {
      const deepFirstError = firstError[Object.keys(firstError)[0]];

      if (deepFirstError && typeof deepFirstError[0] === 'string') {
        return deepFirstError[0];
      }
    }
  }

  if (typeof errorMessage === 'string') {
    return errorMessage;
  }

  return defaultError;
};

export const renderErrorMessage = errorMessage => {
  if (typeof errorMessage === 'object' &&
    errorMessage !== null
  ) {
    return Object.keys(errorMessage).map(key => {
      if (typeof errorMessage[key] === 'string') {
        return (
          <ErrorDescription key={key}>
            {!errorMessage[key].includes('<html') ? errorMessage[key] : defaultError}
          </ErrorDescription>
        );
      }

      return (
        <ErrorDescription key={key}>
          {Array.isArray(errorMessage[key]) ? errorMessage[key][0] : defaultError}
        </ErrorDescription>
      );
    });
  }

  if (typeof errorMessage === 'string' && !errorMessage.includes('<html')) {
    return errorMessage;
  }

  return defaultError;
};

const handleReplaceSocials = (
  template,
  templatePath,
  facebookUsername,
  linkedinPage,
  twitterUsername
) => {
  if (facebookUsername) {
    set(
      template,
      `${templatePath}[0].url`,
      facebookUsername.includes('facebook.com') ? facebookUsername : `https://facebook.com/${facebookUsername}`
    );
  }

  if (linkedinPage) {
    set(
      template,
      `${templatePath}[1].url`,
      linkedinPage.includes('linkedin.com') ? linkedinPage : `https://www.linkedin.com/${linkedinPage}`
      );
  }

  if (twitterUsername) {
    set(
      template,
      `${templatePath}[2].url`,
      twitterUsername.includes('twitter.com') ? twitterUsername : `https://twitter.com/${twitterUsername}`
    );
  }
};

const handleReplaceDataText = (template, templatePath, name, fullAddress) => get(
  template,
  templatePath
)
  .replace(
    '--{ORGANIZATION_NAME}--',
    name
  )
  .replace(
    '--{ORGANIZATION_ADDRESS}--',
    fullAddress
  );

const handleReplaceText = (template, templatePath, email, name, fullAddress) => get(
  template,
  templatePath
)
  .replace(
    '--{ORGANIZATION_EMAIL_ADDRESS}--',
    email
  )
  .replace(
    '--{ORGANIZATION_NAME}--',
    name
  )
  .replace(
    '--{ORGANIZATION_ADDRESS}--',
    fullAddress
  );

export const handleFillInTemplate1 = (template1, {
  name,
  fullAddress,
  linkedinPage,
  twitterUsername,
  facebookUsername,
}) => {
  const filledDataText = handleReplaceDataText(
    template1,
    template1PathToOrgName,
    name,
    fullAddress
  );

  set(template1, template1PathToOrgName, filledDataText);
  handleReplaceSocials(
    template1,
    template1PathToSocials,
    facebookUsername,
    linkedinPage,
    twitterUsername
  );
};

export const handleFillInTemplate2 = (template2, {
  email,
  name,
  fullAddress,
  linkedinPage,
  twitterUsername,
  facebookUsername,
}) => {
  const filledText2 = handleReplaceText(
    template2,
    template2PathToOrgData,
    email,
    name,
    fullAddress
  );

  set(template2, template2PathToOrgData, filledText2);
  handleReplaceSocials(
    template2,
    template2PathToSocials,
    facebookUsername,
    linkedinPage,
    twitterUsername
  );
};

export const handleFillInBroadcastTemplate1 = (template1, {
  name,
  fullAddress,
  linkedinPage,
  twitterUsername,
  facebookUsername,
}) => {
  const filledDataText = handleReplaceDataText(
    template1,
    broadcastTemplate1PathToOrgName,
    name,
    fullAddress
  );

  set(template1, broadcastTemplate1PathToOrgName, filledDataText);
  handleReplaceSocials(
    template1,
    broadcastTemplate1PathToSocials,
    facebookUsername,
    linkedinPage,
    twitterUsername
  );
};

export const handleFillInBroadcastTemplate2 = (template2, {
  email,
  name,
  fullAddress,
  linkedinPage,
  twitterUsername,
  facebookUsername,
}) => {
  const filledText2 = handleReplaceText(
    template2,
    broadcastTemplate2PathToOrgData,
    email,
    name,
    fullAddress
  );

  set(template2, broadcastTemplate2PathToOrgData, filledText2);
  handleReplaceSocials(
    template2,
    broadcastTemplate2PathToSocials,
    facebookUsername,
    linkedinPage,
    twitterUsername
  );
};

export const handlefinalFormParseIdentity = value => (value);

export const handleNumberFirstDigitsFormat = value => {
  const numericOnly = value.replace(/[^0-9]/g, '');

  if (numericOnly.length < 9) {
    return numericOnly;
  }
  const number = formatPhoneNumber(value);

  return number;
};

export const isGeneralError = (errors, fieldsWithError) => {
  if (errors) {
    if (typeof errors === 'string') {
      return true;
    }

    return !Object.keys(errors).some(errorKey => fieldsWithError.includes(errorKey));
  }

  return false;
};

export const getOnePointGeojson = coordinates => ({
  data: {
    features: [
      {
        geometry: {
          coordinates,
          type: 'Point',
        },
        type: 'Feature',
      },
    ],
    type: 'FeatureCollection',
  },
  type: 'geojson',
});

export const getLocations = contactsList => {
  const locations = [];

  for (let i = 0; i < contactsList.length; i += 1) {
    for (let j = 0; j < contactsList[i].locations.length; j += 1) {
      if (contactsList[i].locations[j].point) {
        locations.push({
          geometry: {
            coordinates: contactsList[i].locations[j].point.coordinates,
            type: 'Point',
          },
          properties: {
            contactUuid: contactsList[i].uuid,
          },
          type: 'Feature',
        });
      }
    }
  }

  return locations;
};

export const getPointsGeojson = features => ({
  cluster: true,
  clusterMaxZoom: 14,
  clusterRadius: 50,
  data: {
    features,
    type: 'FeatureCollection',
  },
  type: 'geojson',
});

export const addBoundaryToMap = (map, feature) => {
  const boundaryId = `boundary-${feature.properties.uuid}`;

  if (map.getSource(boundaryId)) {
    return;
  }

  map.addSource(boundaryId, {
    data: feature,
    type: 'geojson',
  });
  map.addLayer({
    id: `${boundaryId}-fill`,
    layout: {},
    paint: {
      'fill-color': '#0080ff',
      'fill-opacity': 0.5,
    },
    source: boundaryId,
    type: 'fill',
  });
  // Add a black outline around the polygon.
  map.addLayer({
    id: `${boundaryId}-outline`,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 3,
    },
    source: boundaryId,
    type: 'line',
  });

  const popup = new mapboxgl.Popup();

  map.on('mouseenter', `${boundaryId}-fill`, e => {
    const { name } = e.features[0].properties;

    popup
      .setLngLat(e.lngLat)
      .setHTML(name)
      .addTo(map);
  });
  map.on('mouseleave', `${boundaryId}-fill`, () => {
    popup.remove();
  });
};

export const addFullScreenMapButton = map => map.addControl(new mapboxgl.FullscreenControl());

export const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
  }

  return str;
};

export const renderListWithCommaSeparator = list => list.map((item, i) => [
  i > 0 && ', ',
  <span key={item}>{item}</span>,
]);

export const renderPartialListWithCommaSeparator = list => `
  ${list[0].name}
  ${list[1]?.name ? `, ${list[1]?.name}` : ''}
  ${list[2]?.name ? '...' : ''}
`;

export const handleMergeCorrectTime = (selectedDate, selectedTime) => {
  const date = dayjs(selectedDate).format('YYYY-MM-DD');
  const hours = dayjs(selectedTime).format('HH');
  const minutes = dayjs(selectedTime).format('mm');

  return `${date}T${hours}:${minutes}`;
};

export const truncate = (str, maxLength = 23) => (str?.length > (maxLength + 3) ? `${str.substring(0, 23) }...` : str);
