import PropTypes from 'prop-types';
import {
  Field,
  Form,
} from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import { required } from 'utils/validators';

import { postCampaign } from 'store/actions/campaigns';

const AddCampaignDialog = ({
  handleDialogClose,
  isDialogOpen,
  title,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();

  const handleDialogSave = () => {
    document.querySelector('.campaign-form').dispatchEvent(new Event('submit', {
      bubbles: true,
      cancelable: true,
    }));
  };

  const onSubmit = async values => {
    const params = {
      ...values,
      organization: organizationUuid,
    };

    try {
      await dispatch(postCampaign(params));

      return handleDialogClose();
    } catch (error) {
      return error;
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleDialogClose}
      open={isDialogOpen}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
          }) => (
            <form
              className="campaign-form"
              onSubmit={handleSubmit}
            >
              <Field
                name="name"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    autoFocus
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched ? (meta.error || meta.submitError) : ''}
                    label="Campaign name"
                    margin="dense"
                    type="text"
                  />
                )}
              </Field>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleDialogSave}
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddCampaignDialog.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddCampaignDialog;
