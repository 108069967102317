import { createTheme } from '@mui/material/styles';

import { colors } from './theme';

export const muiTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // '&.MuiButton-textPrimary': {
          //   color: '#000', // Reset to default for primary buttons
          // },
          '&.MuiButton-textPrimary.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        a: {
          color: '#06c',
          fontWeight: 'bold',
          textDecoration: 'none',
        },
        body: {
          height: '100%',
        },
        html: {
          height: '100%',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '&& .MuiTableSortLabel-icon': {
              color: 'inherit',
              opacity: 1,
            },
            color: 'inherit',
          },
          '&:hover': {
            '&& .MuiTableSortLabel-icon': {
              color: 'inherit',
              opacity: 1,
            },
            color: 'inherit',
          },
          color: 'inherit',
        },
      },
    },
  },
  palette: {
    blue: {
      contrastText: colors.white,
      dark: colors.blue,
      light: colors.blue,
      main: colors.blue,
    },
    error: {
      contrastText: colors.white,
      dark: colors.error,
      light: colors.error,
      main: colors.error,
    },
    green: {
      contrastText: colors.white,
      dark: colors.green,
      light: colors.green,
      main: colors.green,
    },
    grey: {
      contrastText: colors.white,
      dark: colors.gray70,
      light: colors.gray70,
      main: colors.gray70,
    },
    primary: {
      main: '#426ABB',
    },
    secondary: {
      main: '#00A751',
    },
    yellow: {
      contrastText: colors.white,
      dark: colors.yellow,
      light: colors.yellow,
      main: colors.yellow,
    },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
});
