export const colors = {
  alto: '#E0E0E0',
  backgroundColor: '#F5F5F6',
  black: '#000',
  blackSqueeze: '#F0F4FA',
  blue: '#568AF4',
  borderColor: '#E4E4E4',
  borderColor2: '#E4E7EC',
  borderTransparentGray: 'rgba(0, 0, 0, 0.12)',
  boulder: '#757575',
  bunker: '#0E1118',
  codGray: '#1C1C1C',
  dodgerBlue: '#2979FF',
  error: '#E96A6A',
  errorBackground: '#FDEFE9',
  footerBackground: '#1C2429',
  gallery: '#EFEFEF',
  gold: '#FFD600',
  gray70: '#40525D',
  grayBackground: '#EAEDEE',
  green: '#008641',
  greenBackground: '#E5FEF1',
  greyBackground: '#DCE3E7',
  hawkesBlue: '#DCE6FC',
  headerBackground: '#568AF4',
  hotCinnamon: '#E56125',
  lightBlack: '#101828',
  lightGold: 'rgba(255, 214, 0, 0.3)',
  mercury: '#E1E1E1',
  midnightBlue: '#00306B',
  osloGray: '#8D8F93',
  pageBackground: '#F7F7F7',
  people: '#DDE8FD',
  placeholder: '#A2A2A2',
  primary: '#426ABB',
  secondary: '#00A751',
  tableHeaderGray: '#40525D',
  text: '#667085',
  warning: '#FF0000',
  white: '#FFF',
  wildSand: '#F5F5F5',
  yellow: '##CCAB00',
  yellowBackground: '#FFF7CD',
  yellowWarning: '#EED202',
};

/* eslint-disable sort-keys */
export const theme = {
  colors: {
    ...colors,
  },
  spacings: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '24px',
    xxl: '32px',
    xxxl: '40px',
    xxxxl: '48px',
    xxxxxl: '56px',
  },
};
